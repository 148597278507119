/**
 * Created by Hp on 21/09/2017.
 */
(function () {
    'use strict';
    module.exports = generalPaymentService;

    generalPaymentService.$inject = ["mnWebSocket", "$http", "$q"];

    function generalPaymentService(mnWebSocket, $http, $q) {
        var self = this;
        self.deletePayment = deletePayment;
        self.getPayment = getPayment;
        self.updatePaymentLines = updatePaymentLines;
        self.savePayment = savePayment;
        self.getUnpaidInvoices = getUnpaidInvoices;
        self.getUnpaidContracts = getUnpaidContracts;
        self.getUnpaidHealthCareServiceOrder = getUnpaidHealthCareServiceOrder;

        function deletePayment(_id, view_name) {
            var deferred = $q.defer();
            var url = "/api/" + view_name + "/" + _id + "/";
            $http.delete(url)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getPayment(_id, view_name) {
            var deferred = $q.defer();
            var url = "/api/" + view_name + "/" + _id + "/";
            $http.get(url)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function updatePaymentLines(_id, lines, view_name) {
            var deferred = $q.defer();
            var url = '/api/' + view_name + '/' + _id + "/";

            $http.put(url, {lines: lines}).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function savePayment(payment, view_name, _mnModel) {
            var deferred = $q.defer();
            var url = `/api/${view_name}/${!_.isNil(payment.id) ? payment.id + "/" : ""}`;

            $http.post(url, payment).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getUnpaidInvoices(query) {
            return mnWebSocket.call('general_payment.AbstractPayment.get_unpaid_invoices', query);
        }

        function getUnpaidContracts(query) {
            return mnWebSocket.call('general_payment.AbstractPayment.get_unpaid_contracts', query);
        }

        function getUnpaidHealthCareServiceOrder(query) {
            return mnWebSocket.call('general_payment.AbstractPayment.get_health_care_orders', query);
        }
    }
})();