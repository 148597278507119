(function () {
    'use strict';
    const GET_INFO_ACK_PKG_LENGTH = 256 * 2 + 8;
    const BT_READ_FILE_NAME_MAX_LENGTH = 30;
    const COMMON_PKG_LENGTH = 8;
    const CMD_HEADER = 0xAA;
    const CMD_WORD_START_READ = 0x03;
    const N_CMD_WORD_START_READ = 0xFC;
    const CMD_WORD_READ_CONTENT = 0x04;
    const N_CMD_WORD_READ_CONTENT = 0xFB;

    var mnOximetryChart = {
        controller: mnOximetryChartCtrl,
        controllerAs: "vm",
        bindings: {
            realTime: "=mnRealtime",
            chartObject: "=?chartObject",
            bluetoothServer: "=mnBluetoothServer",
            bluetoothDevice: "=mnBluetoothDevice",
            disconnectDevice: "<mnDisconnectDevice",
            exam: "=mnExam",
            oxiServices: "=oxiServices",

            formValid: "<mnValidForm",
            defaultParams: "=mnDefaultParams"
        },
        template: require('oximetry/views/oximetry-chart.tpl.html')
    };
    mnOximetryChartCtrl.$inject = ["$interval", "$mdDialog", "oximetryService", "system", "$scope"];

    function mnOximetryChartCtrl($interval, $mdDialog, oximetryService, system, $scope) {

        var vm = this;
        var chartParams = require('../json/oximetery-chart.json');
        var fullDateTimeFormat = system['full_datetime_format'].js;
        var oxiDevices = require("../json/oxi-services.json");

        var current_bearing_duration = 0;
        var bearing_idx = 0;
        var playBackMeasurements = [];

        vm.$onInit = init;
        vm.$onDestroy = destroy;
        vm.startExam = startExam;
        vm.stopExam = stopExam;
        vm.isReadOnly = isReadOnly;
        vm.reportBreak = reportBreak;
        vm.importExam = importExam;
        vm.endExam = endExam;
        vm.showButton = showButton;
        var exam_end_time = 0;

        function init() {
            // vm.oxiServices = oxiDevices[_.get(vm.defaultParams, 'device_model')];
            ;vm.chartObject = null;
            vm.stopped = false;
            vm.time_idx = 0;
            vm.timer = {value: "", sec: 0, min: 0, hr: 0};
            vm.writeCharacteristic = null;
            vm.filesList = [];
            vm.progress = 0;
            loadChartData();
            $scope.$watch("vm.exam.id", loadExam);
        }


        function destroy() {
            vm.disconnectDevice();
        }

        function loadExam() {
            if (!_.isNil(vm.exam.id) || vm.exam.is_imported) {
                vm.exam.recovery = _.map(vm.exam.recovery, function (e) {
                    e.fillColor = _.get(vm.defaultParams, 'chart_colors.recovery') || "#df2037";
                    return e;
                });
                vm.chartObject.dataProvider = _.map(_.cloneDeep(_.concat(vm.exam.measurements, vm.exam.recovery || [])), function (e) {
                    e.date = moment(e.date, fullDateTimeFormat);
                    return e;
                });
                vm.chartObject.guides = [];
                _.forEach(vm.exam.breaks, function (item) {
                    vm.chartObject.guides.push({
                        date: item.start_time,
                        toDate: item.end_time,
                        lineColor: _.get(vm.defaultParams, 'chart_colors.break') || "#b7e021",
                        lineAlpha: 1,
                        fillAlpha: 0.2,
                        fillColor: _.get(vm.defaultParams, 'chart_colors.break') || "#b7e021",
                        dashLength: 2,
                        inside: true,
                        labelRotation: 90,
                        label: item.reason || item.other_reason
                    });
                });
                vm.chartObject.validateData();
            } else {
                reInitChart();
            }
        }

        function reInitChart() {
            vm.chartObject.dataProvider = [];
            vm.chartObject.guides = [];
            vm.chartObject.validateData();
        }

        function startExam() {
            reInitChart();
            vm.exam.start_datetime = moment().format(fullDateTimeFormat);
            if (_.get(vm.bluetoothServer, 'name') == "O2Ring 7599") {
                enableO2RingMeasurements();
            } else if (_.get(vm.bluetoothServer, 'name') == "AP-20:00094") {
                enableAP20Measurements();
            } else {
                vm.bluetoothDevice.getPrimaryService(vm.oxiServices['noninOxiService']['code'])
                    .then(service => service.getCharacteristic(vm.oxiServices['noninOxiService']['oximetry_measurement']))
                    .then(characteristic => {
                        vm.measurementCaracteristic = characteristic;
                        enableMeasurementCharacteristics();
                    })
                    .catch(err => console.log('error:', err));
            }
        }

        function enableMeasurementCharacteristics(resume = false) {
            vm.measurementCaracteristic.startNotifications()
                .then(myCharacteristic => {
                    vm.measurementCaracteristic = myCharacteristic;
                    resume ? timerResume() : timerStart();
                    vm.measurementCaracteristic.addEventListener('characteristicvaluechanged', handleNotifications)
                })
        }

        function handleNotifications(event) {
            let data = handleData(event.target.value.buffer);
            if (vm.chartObject.dataProvider.length > 19) vm.chartObject.dataProvider.shift();

            vm.chartObject.dataProvider.push(_.assign(_.cloneDeep(data), {date: moment(data.date, fullDateTimeFormat)}));
            vm.chartObject.validateData();
        }
        function toHexArray(byteArray) {
          return Array.prototype.map.call(byteArray, function(byte) {
            return ('0' + (byte & 0xFF).toString(16)).slice(-2);
          }) ;
        }
        function handleData(value) {
            let a = new Uint8Array(value);
            vm.time_idx++;
            var meas_date_time = new Date(new Date().setHours(0, 0, vm.time_idx));

            let data = {
                date: moment(meas_date_time, fullDateTimeFormat).format(fullDateTimeFormat),
                spo: a[7],
                hr: handleHr(a),
                idx: handleIdx(a),
                fillColor: "#fff"
            };
            if (vm.exam['type'] === "six-min" && meas_date_time.getTime() > new Date(new Date().setHours(0, 6, 1)).getTime()) {
                vm.stopExam();
            }
            // stop here réentrainement à l'effort & wats
            if (vm.exam.type === 're-training-effort') {
                if (bearing_idx < vm.exam.protocol.bearing.length && meas_date_time.getTime() > new Date(new Date().setHours(0, current_bearing_duration + vm.exam.protocol.bearing[bearing_idx].duration, 1)).getTime()) {
                    current_bearing_duration += vm.exam.protocol.bearing[bearing_idx].duration;
                    bearing_idx < vm.exam.protocol.bearing.length - 1 ? bearing_idx += 1 : _.noop();
                }
                data.watts = vm.exam.protocol.bearing[bearing_idx].watts;
            }
            if (vm.exam.stop_datetime) {
                data.fillColor = _.get(vm.defaultParams, 'chart_colors.recovery') || "#e45a62";
                vm.exam.recovery.push(data);
            } else {
                vm.exam.measurements.push(data);
            }
            return data;
        }

        function handleHr(a) {
            return _.get(vm.bluetoothServer, 'name') == "O2Ring 7599" ? oximetryService.ByteArrayToInt32([a[8], a[9]]) : a[9];
        }

        function handleIdx(a) {
            return _.get(vm.bluetoothServer, 'name') == "O2Ring 7599" ? vm.time_idx : a[6]
        }

        function is_MaxDuration() {

            if (vm.exam.stop_datetime) {
                return new Date(new Date().setHours(0, 0, vm.time_idx)).getTime() > exam_end_time;
            } else {
                if (vm.exam.type === 'six-min') {
                    return new Date(new Date().setHours(0, 0, vm.time_idx)).getTime() > new Date(new Date().setHours(0, 6, _.get(vm.defaultParams, 'recovery_duration') || 60)).getTime();
                } else {
                    return false;
                }
            }

        }

        function stopExam() {
            vm.exam.stop_datetime = moment().format(fullDateTimeFormat);
            exam_end_time = new Date(new Date().setHours(vm.timer.hr, vm.timer.min, vm.timer.sec + (_.get(vm.defaultParams, 'recovery_duration') || 20))).getTime();
        }

        function endExam() {
            timerStop();
            if (vm.defaultParams['auto_save']) {
                if (vm.formValid) oximetryService.saveExam(vm.exam).then(function success(data) {
                    vm.exam = data;
                });
            }

            if (vm.measurementCaracteristic) {
                try {
                    vm.measurementCaracteristic.removeEventListener('characteristicvaluechanged',
                        handleNotifications);
                    vm.exam.end_datetime = moment().format(fullDateTimeFormat);
                    vm.measurementCaracteristic.stopNotifications();

                } catch (error) {
                    console.log('Argh! ' + error);
                }
            }

            vm.chartObject.dataProvider = _.map(_.cloneDeep(_.concat(vm.exam.measurements, vm.exam.recovery || [])), function (e) {
                e.date = moment(e.date, fullDateTimeFormat);
                return e;
            });
            vm.chartObject.validateData();
        }

        function loadChartData() {
            chartParams['valueAxes'][_.findIndex(chartParams['valueAxes'], {id: 'spo1'})].axisColor = _.get(vm.defaultParams, 'chart_colors.spo');
            chartParams['valueAxes'][_.findIndex(chartParams['valueAxes'], {id: 'hr1'})].axisColor = _.get(vm.defaultParams, 'chart_colors.hr');
            chartParams['valueAxes'][_.findIndex(chartParams['valueAxes'], {id: 'watts1'})].axisColor = _.get(vm.defaultParams, 'chart_colors.watts');

            chartParams['graphs'][_.findIndex(chartParams['graphs'], {valueField: 'spo'})].lineColor = _.get(vm.defaultParams, 'chart_colors.spo');
            chartParams['graphs'][_.findIndex(chartParams['graphs'], {valueField: 'hr'})].lineColor = _.get(vm.defaultParams, 'chart_colors.hr');
            chartParams['graphs'][_.findIndex(chartParams['graphs'], {valueField: 'watts'})].lineColor = _.get(vm.defaultParams, 'chart_colors.watts');

            vm.chartObject = AmCharts.makeChart("chart-div", _.assign(_.cloneDeep(chartParams), {"dataProvider": []}));
            vm.chartObject.addListener("dataUpdated", zoomChart);
            zoomChart();


        }

        function zoomChart() {
            vm.chartObject.zoomToIndexes(vm.chartObject.dataProvider.length - 20, vm.chartObject.dataProvider.length - 1);
        }

        function isReadOnly(is_starting = false) {

            return is_starting ? !_.isNil(vm.exam.start_datetime) : !vm.exam.start_datetime || !_.isNil(vm.exam.id);
        }

        function showButton(type) {
            switch (type) {
                case "start":
                    return vm.exam.type != 'nocturne' && _.isNil(vm.exam.start_datetime);
                case "end":
                    return vm.exam.type != 'nocturne' && !_.isNil(vm.exam.start_datetime) && !_.isNil(vm.exam.stop_datetime) && _.isNil(vm.exam.end_datetime);
                case "stop":
                    return vm.exam.type != 'nocturne' && !_.isNil(vm.exam.start_datetime) && _.isNil(vm.exam.stop_datetime);

                default:
                    return false;
            }
        }

        function reportBreak() {
            var break_time_idx = vm.time_idx;
            var break_moment = new Date(new Date().setHours(0, 0, break_time_idx));

            $mdDialog.show(_.assign(require('oximetry/dialogs/break-dialog'), {
                locals: {
                    breakReasons: _.get(vm.defaultParams, 'break_reasons'),
                    break: {start_time: _.cloneDeep(moment(break_moment, fullDateTimeFormat).format(fullDateTimeFormat))}
                }
            })).then(reportCallBack);

            function reportCallBack(data) {
                if (data.type === 'short') {
                    break_moment.setHours(0, 0, break_time_idx + _.get(vm.defaultParams, 'short_break_duration') || 5);
                    data.end_time = _.cloneDeep(moment(break_moment, fullDateTimeFormat).format(fullDateTimeFormat));
                }


                if (data.type === 'long') {
                    break_moment.setHours(0, 0, vm.time_idx + _.get(vm.defaultParams, 'short_break_duration') || 5);
                    data.end_time = _.cloneDeep(moment(break_moment, fullDateTimeFormat).format(fullDateTimeFormat));
                }
                vm.exam.breaks.push(data);
            }
        }

        function importExam() {
            reInitChart();
            vm.exam.start_datetime = moment().format(fullDateTimeFormat);
            if (_.get(vm.bluetoothServer, 'name') == "O2Ring 7599") {
                importO2RingMeasurements();
            } else {
                vm.bluetoothDevice.getPrimaryService(vm.oxiServices['pulseOxiService']['code'])
                    .then(service => service.getCharacteristic(vm.oxiServices['pulseOxiService']['spot_check_measurement']))
                    .then(c => {
                        vm.spotCheckCaracteristic = c;
                        return vm.spotCheckCaracteristic.startNotifications();
                    })
                    .then(c_post => {
                        vm.spotCheckCaracteristic = c_post;
                        vm.spotCheckCaracteristic.addEventListener('characteristicvaluechanged', _.get(vm.defaultParams, 'device_model') == "nonin_3150" ? handleMemoryPlayBackNotification : handleSpotCheckNotification);
                        enableRecordAccess();
                    })

                    .catch(err => console.log('error:', err));
            }
        }

        function enableRecordAccess() {
            vm.bluetoothDevice.getPrimaryService(vm.oxiServices['pulseOxiService']['code'])
                .then(service => service.getCharacteristic(vm.oxiServices['pulseOxiService']['record_access_control']))
                .then(c => {
                    vm.recordCaracteristic = c;
                    return vm.recordCaracteristic.startNotifications();
                })
                .then(c_post => {
                    vm.recordCaracteristic = c_post;
                    vm.recordCaracteristic.writeValue(Uint8Array.of(0x71, 0x4E, 0x4D, 0x49));
                    vm.recordCaracteristic.addEventListener('characteristicvaluechanged', handleRecordAccessNotification);
                })
                .catch(value => {
                    console.log('indication value.', value);
                })
        }

        function handleSpotCheckNotification(event) {
            var value = new Uint8Array(event.target.value.buffer);
            var meas_date_time = oximetryService.newDateFromArray(_.concat(oximetryService.toInt16(value.slice(5, 7)), value[7] - 1, Array.from(value.slice(8, 12))));

            let data = {
                date: moment(meas_date_time, fullDateTimeFormat).format(fullDateTimeFormat),
                spo: oximetryService.readSFLOAT(new DataView(new Uint8Array(value.slice(1, 3)).buffer), 0, true),
                hr: oximetryService.readSFLOAT(new DataView(new Uint8Array(value.slice(3, 5)).buffer), 0, true),
                fillColor: "#fff"
            };
            vm.exam.measurements.push(data);
            vm.chartObject.dataProvider.push(_.assign(_.cloneDeep(data), {date: moment(data.date, fullDateTimeFormat)}));

            vm.chartObject.validateData();
        }

        function handleMemoryPlayBackNotification(event) {
            var value = new Uint8Array(event.target.value.buffer);
            playBackMeasurements.push(Array.from(value));
        }

        function formatPlayBackDate(date_array) {

            let m = moment();


            m.set('years', 2000 + date_array[3]);
            m.set('months', date_array[0]);
            m.set('date', date_array[1]);
            m.set('hours', date_array[7]);
            m.set('minutes', date_array[4]);
            m.set('seconds', date_array[6]);


            return m;
        }

        function handleRecordAccessNotification(event) {
            let value = new Uint8Array(event.target.value.buffer);
            if (value[0] == 243) {
                let res = _.chain(playBackMeasurements)
                    .flatten()
                    .toString()
                    .split("254,253,251,")
                    .slice(1, -1)
                    .map((e) => {
                        let data = _.split(e, ",");
                        let meas_time = formatPlayBackDate(_.chain(data).slice(12, 21).map(_.parseInt).value())
                        let secondRate = data[0];
                        return _.chain(data).slice(30, data.length).map(_.parseInt).chunk(3)
                            .map((m, i) => {
                                return {
                                    hr: m[0],
                                    spo: m[1],
                                    date: meas_time.subtract("seconds", secondRate).format(fullDateTimeFormat),
                                    fillColor: "#fff"
                                }
                            })
                            .value()

                    })
                    .flatten()
                    .reject((e) => {
                        return _.includes([255, NaN], e.hr)
                    })
                    .reverse()
                    .forEach((data) => {

                        vm.exam.measurements.push(data);

                        vm.chartObject.dataProvider.push(_.assign(_.cloneDeep(data), {date: moment(data.date, fullDateTimeFormat)}));
                    })
                    .value();
                vm.chartObject.validateData();
                zoomChart();
            }

        }

        // **********timer************
        function timerResume() {
            vm.timerProcess = $interval(timerHandler, 1000);
        }

        function timerStart() {

            vm.timerProcess = $interval(timerHandler, 1000);
            timerReset();
        }

        function timerReset() {
            vm.timer.value = null;
            vm.timer.sec = 0;
            vm.timer.min = 0;
            vm.timer.hr = 0;
        }

        function timerStop() {

            if (vm.timerProcess) {
                $interval.cancel(vm.timerProcess);
            }
        }

        function timerHandler() {

            if (is_MaxDuration()) vm.endExam();
            if (++vm.timer.sec === 60) {
                vm.timer.sec = 0;
                if (++vm.timer.min === 60) {
                    vm.timer.min = 0;
                    if (++vm.timer.hr === 24) vm.timer.hr = 0;
                }
            }
            vm.timer.value = vm.timer.hr > 0 ? (vm.timer.hr < 10 ? "0" + vm.timer.hr : vm.timer.hr) + ":" : "";
            vm.timer.value += (vm.timer.min < 10 ? "0" + vm.timer.min : vm.timer.min) + ":" + (vm.timer.sec < 10 ? "0" + vm.timer.sec : vm.timer.sec);
        }

        function enableO2RingMeasurements(resume = false) {
            vm.bluetoothDevice.getPrimaryService(_.get(vm.oxiServices, 'OxiService.code'))
                .then(service => {
                    return service.getCharacteristic(_.get(vm.oxiServices, 'OxiService.notifyCharacteristic'))
                })
                .then(characteristic => characteristic.startNotifications())
                .then(myCharacteristic => {
                    vm.measurementCaracteristic = myCharacteristic;
                    vm.measurementCaracteristic.addEventListener('characteristicvaluechanged', handleNotifications);
                    resume ? timerResume() : timerStart();
                })
                .then(c => setInterval(callO2RingMeasurementsCharacteristic, 1000))
                .catch(err => console.log('error:', err));
        }

        function enableAP20Measurements(resume = false) {
            vm.bluetoothDevice.getPrimaryService(_.get(vm.oxiServices, 'OxiService.code'))
                .then(service => {
                    return service.getCharacteristic(_.get(vm.oxiServices, 'OxiService.notifyCharacteristic'))
                })
                .then(characteristic => characteristic.startNotifications())
                .then(myCharacteristic => {
                    vm.measurementCaracteristic = myCharacteristic;
                    vm.measurementCaracteristic.addEventListener('characteristicvaluechanged', handleNotifications);
                    resume ? timerResume() : timerStart();
                })
                .then(c => {
                    callAPMeasurementsCharacteristic()
                })
                .catch(err => console.log('error:', err));
        }

        function callO2RingMeasurementsCharacteristic() {
            return oximetryService.callO2RingCharacteristic(vm.writeCharacteristic, vm.bluetoothServer,
                vm.bluetoothDevice, vm.oxiServices, Uint8Array.of(0xAA, 0x17, 0xE8, 0x00, 0x00, 0x00, 0x00, 0x1B));
        }

        function callAPMeasurementsCharacteristic() {
            return oximetryService.callO2RingCharacteristic(vm.writeCharacteristic, vm.bluetoothServer,
                vm.bluetoothDevice, vm.oxiServices, Uint8Array.of(0xAA, 0x55, 0xF0, 0x02, 0x82, 0xFB));
        }

        function importO2RingMeasurements() {
            vm.result = [];
            vm.readCount = 0;
            vm.flag = false;
            vm.lastPkgBytes = 0;
            vm.num = 0;
            vm.curNum = 0;
            vm.curReadNum = 0;
            vm.downLoadSize = 0;
            vm.dataPool = null;
            vm.fileSize = null;

            vm.bluetoothDevice.getPrimaryService(_.get(vm.oxiServices, 'OxiService.code'))
                .then(service => {
                    return service.getCharacteristic(_.get(vm.oxiServices, 'OxiService.notifyCharacteristic'))
                })
                .then(characteristic => characteristic.startNotifications())
                .then(myCharacteristic => {
                    vm.measurementCaracteristic = myCharacteristic;
                    vm.measurementCaracteristic.addEventListener('characteristicvaluechanged', handleMemoryData);
                })
                .then(c => getFilesList())
                .catch(err => console.log('error:', err));
        }

        function getFilesList() {
            vm.measurementCaracteristic.removeEventListener('characteristicvaluechanged', handleFileContent);
            return oximetryService.callO2RingCharacteristic(vm.writeCharacteristic, vm.bluetoothServer,
                vm.bluetoothDevice, vm.oxiServices, Uint8Array.of(0xAA, 0x14, 0xEB, 0x00, 0x00, 0x00, 0x00, 0xC6));
        }


        function handleMemoryData(event) {
            let data = new Uint8Array(event.target.value.buffer)
            let resultData = readData(data, GET_INFO_ACK_PKG_LENGTH);
            formatInfo(resultData);
        }

        function readData(buf, wantedBytes) {
            let lastResult = _.range(0, wantedBytes, 0);
            if (vm.readCount < wantedBytes) {
                if (vm.readCount == 0) {
                    vm.result = Array.from(buf);
                    vm.readCount += buf.length;
                } else {
                    if (vm.result != null) {
                        vm.result = _.concat(vm.result, Array.from(buf))
                        vm.readCount += buf.length;
                    }
                }
                vm.flag = false;
            }
            if (vm.readCount == wantedBytes) {
                vm.flag = true;
                vm.readCount = 0;
                lastResult = vm.result;
                vm.result = null;
            }
            return vm.flag ? lastResult : null;
        }

        function formatInfo(resultData) {
            if (resultData != null) {
                if (resultData[0] == 85) {
                    if (resultData[1] == 0) {
                        let dataBuf = new Array(GET_INFO_ACK_PKG_LENGTH - 8)
                        let info = "";
                        for (var i = 0; i < dataBuf.length; i++) {
                            if (resultData[i + 7] != 0) {
                                dataBuf[i] = String.fromCharCode(resultData[i + 7]);
                                info += dataBuf[i];
                            }
                        }
                        vm.filesList = _.chain(JSON.parse(info.toString())).get('FileList').split(',').compact().value();
                        //TODO save files list as vm.files list to show in popup

                        //show the dialog of files names
                        $mdDialog.show(_.assign(require('oximetry/dialogs/files-select-dialog'), {
                            locals: {
                                filesList: _.cloneDeep(vm.filesList)
                            }
                        })).then(handleReadFile);
                    } else {
                        console.log('err : read info ack error')
                    }
                } else {
                    console.log('err : reply head error')
                }
            }
        }


        function handleReadFile(fileName) {

            if (fileName) {
                let buf = startReadBuf(fileName);
                vm.result = [];
                vm.readCount = 0;
                vm.flag = false;
                vm.measurementCaracteristic.removeEventListener('characteristicvaluechanged', handleMemoryData);
                vm.measurementCaracteristic.addEventListener('characteristicvaluechanged', handleFileReadReply);
                oximetryService.callO2RingCharacteristic(vm.writeCharacteristic, vm.bluetoothServer,
                    vm.bluetoothDevice, vm.oxiServices, buf);
            }
        }

        function startReadBuf(fileName) {
            if (fileName == null ||
                fileName.length > BT_READ_FILE_NAME_MAX_LENGTH) {
                return;
            }
            //+1 as character '\0'
            let _buf = new Uint8Array(COMMON_PKG_LENGTH + fileName.length + 1);
            _buf[0] = CMD_HEADER;
            _buf[1] = CMD_WORD_START_READ;
            _buf[2] = N_CMD_WORD_START_READ;
            _buf[3] = 0; //Package number, the default is 0
            _buf[4] = 0;
            _buf[5] = _buf.length - COMMON_PKG_LENGTH; //data chunk size
            _buf[6] = ((_buf.length - COMMON_PKG_LENGTH) >> 8);

            for (var i = 0; i < fileName.length; i++) {
                _buf[i + 7] = _.parseInt(fileName[i]) + 48;
            }
            _buf[_buf.length - 2] = 0;
            _buf[_buf.length - 1] = oximetryService.calCRC8(_buf);
            return _buf
        }

        function handleFileReadReply(event) {
            let buf = new Uint8Array(event.target.value.buffer);
            if (buf[1] == 0) {
                // vm.fileSize = startReadAckPkg.getFileSize();
                let pkgNum = (buf[3] & 0xFF) | (buf[4] & 0xFF) << 8;
                if (pkgNum < 0) {
                    console.log("err : StartReadAckPkg package number error");
                    return;
                }
                vm.fileSize = (buf[7] & 0xFF) |
                    (buf[8] & 0xFF) << 8 |
                    (buf[9] & 0xFF) << 16 |
                    (buf[10] & 0xFF) << 24;
                if (vm.fileSize <= 0) {
                    console.log("err : StartReadAckPkg File size error");
                    return;
                }
                vm.dataPool = new Array(vm.fileSize);
                vm.num = _.parseInt(vm.fileSize / 512);
                vm.lastPkgBytes = vm.fileSize % 512;
                vm.num += (vm.lastPkgBytes == 0) ? 0 : 1;
                vm.lastPkgBytes += (vm.lastPkgBytes == 0) ? 0 : 8;
                vm.result = [];
                vm.readCount = 0;
                // [170, 4, 251, 1, 0, 0, 0, 124]
                beginReadFile(ReadContentPkg(0));

            } else {
                console.log("err : StartReadAckPkg header error");
                return;
            }
        }

        function ReadContentPkg(pkgNum) {
            if (pkgNum < 0) {
                console.log("ReadContentPkg package number error");
                return;
            }
            let _buf = new Uint8Array(COMMON_PKG_LENGTH);
            _buf[0] = CMD_HEADER;
            _buf[1] = CMD_WORD_READ_CONTENT;
            _buf[2] = N_CMD_WORD_READ_CONTENT;
            _buf[3] = pkgNum;
            _buf[4] = pkgNum >> 8;
            _buf[5] = 0;
            _buf[6] = 0;
            _buf[7] = oximetryService.calCRC8(_buf);
            return _buf;
        }

        function beginReadFile(buf) {
            // [170, 4, 251, 0, 0, 0, 0, 106]
            // [170, 4, 251, 2, 0, 0, 0, 70]
            vm.measurementCaracteristic.removeEventListener('characteristicvaluechanged', handleFileReadReply);
            vm.measurementCaracteristic.addEventListener('characteristicvaluechanged', handleFileContent);
            oximetryService.callO2RingCharacteristic(vm.writeCharacteristic, vm.bluetoothServer,
                vm.bluetoothDevice, vm.oxiServices, buf);
        }

        function handleFileContent(event) {
            let buf = new Uint8Array(event.target.value.buffer);
            // [85, 1, 254, 1, 0, 0, 0, 88]

            // [85, 0, 255, 0, 0, 60, 0, 3, 0, 228, 7, 12, 30, 14, 10, 9, 60, 0, 0, 0]
            // [16, 0, 0, 0, 98, 98, 0, 0, 0, 0, 0, 0, 255, 0, 0, 0, 0, 0, 0, 0]
            // [0, 0, 0, 0, 0, 0, 0, 98, 78, 0, 0, 0, 98, 78, 0, 22, 0, 255, 255, 255]
            // [14, 0, 255, 255, 255, 1, 0, 54]

            // [85, 1, 254, 2, 0, 0, 0, 98]
            let resultReadContent = null;

            let wantedBytes = 512 + 8;
            let lastPkg = new Array(vm.lastPkgBytes);
            let lastResult = new Array(wantedBytes); // new byte[wantBytes];
            if (vm.lastPkgBytes != 0 && vm.curReadNum == vm.num - 1) {
                if (vm.readCount < vm.lastPkgBytes) {
                    if (vm.readCount === 0) {
                        vm.result = Array.from(buf);
                        vm.readCount += buf.length;
                    } else {
                        vm.result = _.concat(vm.result, Array.from(buf))
                        vm.readCount += buf.length;
                    }
                    vm.flag = false;
                    vm.lastBytesFlag = false;
                }
                if (vm.readCount == vm.lastPkgBytes) {
                    vm.flag = true;
                    vm.lastBytesFlag = true;
                    vm.readCount = 0;
                    lastPkg = vm.result;
                    vm.result = null;
                    vm.curReadNum = 0;
                }
            } else {
                if (vm.readCount < wantedBytes) {
                    if (vm.readCount == 0) {
                        vm.result = Array.from(buf);
                        vm.readCount += buf.length;
                    } else {
                        vm.result = _.concat(vm.result, Array.from(buf))
                        vm.readCount += buf.length;
                    }
                    vm.flag = false;
                    vm.lastBytesFlag = false;
                }
                if (vm.readCount == wantedBytes) {
                    vm.flag = true;
                    vm.lastBytesFlag = false;
                    vm.readCount = 0;
                    lastResult = vm.result;
                    vm.result = null;
                    if (vm.lastPkgBytes == 0 && vm.curReadNum == vm.num - 1) vm.curReadNum = 0;
                    else vm.curReadNum++;
                }
            }
            if (vm.flag && !vm.lastBytesFlag) {
                resultReadContent = lastResult;
            } else if (vm.flag && vm.lastBytesFlag) {
                resultReadContent = lastPkg;
            } else {
                resultReadContent = null;
            }


            if (resultReadContent != null) {
                let readContentAckPkgRes = readContentAckPkg(resultReadContent);
                vm.downLoadSize += readContentAckPkgRes.length;
                vm.progress = _.parseInt(vm.downLoadSize * 1.0 / vm.fileSize * 100); // recalculate donne 101
                let dataPool = addDataToPool(readContentAckPkgRes);
                if (dataPool == null) {
                    let readContentPkg = ReadContentPkg(vm.curNum + 1);
                    oximetryService.callO2RingCharacteristic(vm.writeCharacteristic, vm.bluetoothServer,
                        vm.bluetoothDevice, vm.oxiServices, readContentPkg);
                } else {
                    endReadFile();
                }


            }
        }

        function readContentAckPkg(inBuf) {
            let _cmd = null;
            if (inBuf.length > 512 + 8) {
                console.log("ReadContentAckPkg length error");
                return;
            }
            if (inBuf[0] != 85) {
                console.log("ReadContentAckPkg head error");
                return;
            } else if ((_cmd = inBuf[1]) != 0 ||
                inBuf[2] != 255) {
                console.log("ReadContentAckPkg cmd word error");
                return;
            } else if (inBuf[inBuf.length - 1] != oximetryService.calCRC8(inBuf)) {
                console.log("ReadContentAckPkg CRC error");
                return;
            }
            let _pkgNum = (inBuf[3] & 0xFF) | (inBuf[4] & 0xFF) << 8;
            if (_pkgNum < 0) {
                console.log("pkgNum error");
                return;
            }
            let dataLength = (inBuf[5] & 0xFF) | (inBuf[6] & 0xFF) << 8;
            if (dataLength > 512 ||
                dataLength >
                inBuf.length - 8) {
                console.log("ReadContentAckPkg data length error");
                return;
            }
            let _dataBuf = new Uint8Array(dataLength);

            copyArrayToIndex(inBuf, 7, _dataBuf, 0, _dataBuf.length)
            vm.curNum = _pkgNum;
            return _dataBuf;

        }

        function copyArrayToIndex(src, srcPos, dst, dstPos, length) {
            while (length--) dst[dstPos++] = src[srcPos++];
            return dst;
        }

        function addDataToPool(buf) {
            if (buf == null || buf.length == 0 || vm.dataPool == null) {
                return null;
            }
            let curPos = vm.curNum * 512;
            if (curPos + buf.length > vm.dataPool.length) {
                return null;
            }
            copyArrayToIndex(buf, 0, vm.dataPool, curPos, buf.length)
            return vm.curNum == vm.num - 1 ? vm.dataPool : null;
        }

        function endReadFile() {
            console.log('____ end read ____');
            vm.measurementCaracteristic.removeEventListener('characteristicvaluechanged', handleFileContent);
            vm.measurementCaracteristic.addEventListener('characteristicvaluechanged', handleEndFileRead);
            oximetryService.callO2RingCharacteristic(vm.writeCharacteristic, vm.bluetoothServer,
                vm.bluetoothDevice, vm.oxiServices, new Uint8Array([0xAA, 0x05, 0xFA, 0x00, 0x00, 0x00, 0x00, 0x21]));
        }

        function handleEndFileRead(event) {
            let buf = new Uint8Array(event.target.value.buffer);
            vm.measurementCaracteristic.removeEventListener('characteristicvaluechanged', handleEndFileRead);
            initiateReadParams();
            processData();
        }

        function initiateReadParams() {
            vm.result = [];
            vm.readCount = 0;
            vm.flag = false;
            vm.progress = 0;
        }

        function processData() {
            let generalData = _.slice(vm.dataPool, 0, 40);
            let data = _.slice(vm.dataPool, 40);
            let startDate = moment();
            startDate.set('years', oximetryService.ByteArrayToInt32([generalData[2], generalData[3]]));
            startDate.set('months', generalData[4]);
            startDate.set('date', generalData[5]);
            startDate.set('hours', generalData[6]);
            startDate.set('minutes', generalData[7]);
            startDate.set('seconds', generalData[8]);
            let dt = startDate;
            let recordingTime = oximetryService.ByteArrayToInt32([generalData[13], generalData[14]]);
            for (var i = 0; i < data.length; i = i + 5) {
                let spo2 = data[i] != 255 ? data[i] : 0;
                let hr = handleHR([data[i + 1], data[i + 2]]);
                let obj = {
                    date: dt.format(fullDateTimeFormat),
                    spo: spo2,
                    hr: hr != 255 ? hr : 0,
                    fillColor: "#fff"
                };
                hr != 255 && spo2 ? vm.exam.measurements.push(_.cloneDeep(obj)) : _.noop();
                dt = dt.add(4, 'seconds');
            }

            vm.chartObject.dataProvider = _.map(_.cloneDeep(vm.exam.measurements), function (e) {
                e.date = moment(e.date, fullDateTimeFormat);
                return e;
            });
            vm.chartObject.validateData();
        }

        function handleHR(buffer) {
            return buffer[0] == 255 || buffer[0] == 0 ? 255 : oximetryService.ByteArrayToInt32([buffer[0], buffer[1]]);
        }
    }

    module.exports = mnOximetryChart;

})();
